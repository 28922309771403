<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="">
            <h4 class="title">Detail THR</h4>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/data-payroll">Payroll</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link
                    :to="{
                      name: 'DetailTHR',
                      params: {
                        id: formData.id_payroll,
                      },
                    }"
                  >
                    Detail THR</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ formData.nama_karyawan }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div class="card card-detail">
          <div class="d-flex justify-content-between align-items-center">
            <div class="company-wrapper d-flex align-items-center">
              <img
                :src="dataCompany.logo"
                v-if="dataCompany.logo"
                class="mr-3"
                style="width: 90px; height: auto"
              />
              <div class="">
                <div class="title-company">{{ dataCompany.nama }}</div>
                <div class="email-company">{{ dataCompany.email }}</div>
              </div>
            </div>

            <div class="address-company">
              {{ dataCompany.alamat }}
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-between mt-5 header-detail"
          >
            <div class="">
              <div class="title-payroll mb-3">SLIP THR</div>
              <div class="d-flex align-items-center item-detail">
                <div class="text-header">Tanggal bayar</div>
                <div class="text-bold">
                  : {{ formatDate(formData.tanggal_bayar) }}
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-3">
                <div class="d-flex align-items-center item-detail">
                  <div class="text-header">NAMA</div>
                  <div class="text-bold">: {{ formData.nama_karyawan }}</div>
                </div>
                <div class="d-flex align-items-center item-detail">
                  <div class="text-header">NIK</div>
                  <div class="text-bold">: {{ formData.id_karyawan }}</div>
                </div>
                <div class="d-flex align-items-center item-detail">
                  <div class="text-header">REKENING</div>
                  <div class="text-bold">
                    : {{ formData.nomor_rekening }} ({{ formData.bank }})
                  </div>
                </div>
              </div>
              <div class="">
                <div class="d-flex align-items-center item-detail">
                  <div class="text-header">Departemen</div>
                  <div class="text-bold">: {{ formData.nama_divisi }}</div>
                </div>
                <div class="d-flex align-items-center item-detail">
                  <div class="text-header">Jabatan</div>
                  <div class="text-bold">: {{ formData.nama_jabatan }}</div>
                </div>
                <div class="d-flex align-items-center item-detail">
                  <div class="text-header">Masa Jabatan</div>
                  <div class="text-bold">: {{ formData.lengthService }}</div>
                </div>
              </div>
            </div>
          </div>

          <table class="table table-payroll-slip mt-5">
            <tbody>
              <tr>
                <td class="td-block header-table-blue">Nama Item</td>
                <!-- <td  class="td-block header-table-blue">Level</td> -->
                <!-- <td  class="td-block header-table-blue">tanggal</td> -->
                <!-- <td  class="td-block header-table-blue">FRQ</td> -->
                <td class="td-block header-table-blue">nominal</td>
              </tr>
              <tr v-for="(value, index) in ListItem.pemasukan" :key="index">
                <td class="border-colon-right">
                  {{ value.nama }}
                </td>
                <td class="border-colon-right text-right">
                  {{ formatMoney(value.nilai) }}
                </td>
              </tr>
              <tr>
                <td class="td-block text-center header-table-blue">
                  TOTAL THR yang diterima
                </td>
                <td class="text-right text-bold">
                  {{ formatMoney(ListItem.nilai_pemasukan) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

// import $ from 'jquery'
import moment from "moment";
import "moment/locale/id";
import { get_PayrollSlip } from "../../../../actions/payroll";
import { showAlert } from "../../../../helper";
import { DATA } from "../../../../constans/Url";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    SidebarDashboard,
    NavbarDashboard,
  },

  data() {
    return {
      ListItem: {
        pemasukan: [],
        potongan: [],
        subsidi: [],
        nilai_pemasukan: 0,
        nilai_potongan: 0,
        nilai_subsidi: 0,
      },
      DataTable: "",
      selectAll: false,
      dataCompany: {
          logo: '',
          email: '',
          nama: '',
          alamat: '',
        },
      formData: {
        id: "",
        id_payroll: "0",
        kategori: "Gaji",
        bulan: "",
        tanggal_bayar: "",
        keterangan: "",
        akun_perkiraan: "",
        nama_karyawan: "",
        nama_divisi: "",
        nama_jabatan: "",
        alamat: "",
        nomor_rekening: "",
        bank: "",
        lengthService: "",
      },
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  created() {
    this.getData();
  },
  methods: {
    capitlizeFirst(str) {
      // checks for null, undefined and empty string
      if (!str) return;
      return str.match("^[a-z]")
        ? str.charAt(0).toUpperCase() + str.substring(1)
        : str;
    },
    setTerbilang(angka) {
      var bilne = [
        "",
        "satu",
        "dua",
        "tiga",
        "empat",
        "lima",
        "enam",
        "tujuh",
        "delapan",
        "sembilan",
        "sepuluh",
        "sebelas",
      ];

      if (angka < 12) {
        return bilne[angka];
      } else if (angka < 20) {
        return this.setTerbilang(angka - 10) + " belas";
      } else if (angka < 100) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 10)) +
          " puluh " +
          this.setTerbilang(parseInt(angka) % 10)
        );
      } else if (angka < 200) {
        return "seratus " + this.setTerbilang(parseInt(angka) - 100);
      } else if (angka < 1000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 100)) +
          " ratus " +
          this.setTerbilang(parseInt(angka) % 100)
        );
      } else if (angka < 2000) {
        return "seribu " + this.setTerbilang(parseInt(angka) - 1000);
      } else if (angka < 1000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000)) +
          " ribu " +
          this.setTerbilang(parseInt(angka) % 1000)
        );
      } else if (angka < 1000000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000000)) +
          " juta " +
          this.setTerbilang(parseInt(angka) % 1000000)
        );
      } else if (angka < 1000000000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000000000)) +
          " milyar " +
          this.setTerbilang(parseInt(angka) % 1000000000)
        );
      } else if (angka < 1000000000000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000000000000)) +
          " trilyun " +
          this.setTerbilang(parseInt(angka) % 1000000000000)
        );
      }
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatMonth(string) {
      return moment(string + "-01").format("MMMM YYYY");
    },

    formatDate(string) {
      return moment(string).format("DD MMMM YYYY");
    },

    countLengthOfService(date, created_at) {
      var a = moment(created_at);
      var b = moment(date);

      var string = "";

      var years = a.diff(b, "year");
      b.add(years, "years");
      if (years) {
        string += `${years} Tahun `;
      }
      var months = a.diff(b, "months");
      b.add(months, "months");

      if (months) {
        string += `${months} bulan `;
      }

      var days = a.diff(b, "days");

      if (days) {
        string += `${days} hari `;
      }
      return string;
    },

    getData() {
      get_PayrollSlip(
        this.$route.params.id,
        (res) => {
          var { detail, data, company } = res;
          this.formData.id = data.id;
          this.formData.id_payroll = data.id_payroll;
          this.formData.kategori = data.kategori;
          this.formData.bulan = data.bulan;
          this.formData.tanggal_bayar = data.tanggal_bayar;
          this.formData.nama_karyawan = data.nama_karyawan;
          this.formData.nama_divisi = data.nama_divisi;
          this.formData.nama_jabatan = data.nama_jabatan;
          this.formData.alamat = data.alamat_company;
          this.formData.id_karyawan = data.id_karyawan;
          this.formData.bank = data.bank;
          this.formData.nomor_rekening = data.nomor_rekening;
          this.formData.lengthService = this.countLengthOfService(data.tgl_masuk, data.tanggal_bayar);
          this.ListItem = detail;

          this.dataCompany = {
            logo: company.logo ? DATA.COMPANY(company.logo) : "",
            nama: company.nama,
            email: company.email,
            alamat: company.alamat,
          };
        },
        () => {
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Data Slip gaji tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataPayroll",
              });
            },
            onDismiss: () => {
              this.$router.push({
                name: "DataPayroll",
              });
            },
          });
        }
      );
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.title-invoice {
  font-weight: 700;
  font-size: 48px;
  background: linear-gradient(102.18deg, #6ec0fe -17.12%, #61efe7 95.5%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.card {
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}

.total-payroll {
  background: #d7ffea;
  border-radius: 0px 0px 4px 4px;
}

.total > .row:first-child {
  line-height: 40px;
}

.total {
  font-size: 14px;
  border: #e3e3e3 1px solid;
  border-radius: 5px;
}

.total .row {
  padding: 15px 25px;
}

#table-total td {
  background-color: #ffd7d7 !important;
  border: none;
  padding: 0px 25px;
  height: 50px;
  /* border-radius: 10px; */
}

#table-total tr td:first-child {
  border-top-left-radius: 10px;
}

#table-total tr td:last-child {
  border-top-right-radius: 10px;
}

.text {
  font-size: 14px;
}

.address {
  width: 400px;
  font-size: 14px;
}

.title-payroll {
  font-size: 37px;
  color: #263238;
  font-weight: 600;
}


.text-bold {
  font-weight: 600;
}

.text-header {
  font-size: 15px;
  text-transform: uppercase;
  width: 152px;
}

.table-payroll-slip tr,
.table-payroll-slip td {
  font-size: 15px;
  border: 1px solid #e3e3e3;
  text-transform: uppercase;
}

/* .table-payroll-slip tr,
  .table-payroll-slip td.border-colon-right {
    border-right-width: 0 !important;
  } */

.table-payroll-slip tr,
.table-payroll-slip td.border-colon {
  border-left: 0 !important;
}

.table-payroll-slip td {
  padding: 14px 12px !important;
  height: 25px !important;
}

.table-payroll-slip td.td-block {
  background-color: #666666;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
}

.table-payroll-slip tr.border-none,
.table-payroll-slip .border-none td {
  border: none !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f5f7f8;
}

.title-company {
  font-weight: 700;
  font-size: 20px;
  color: #0e2c4e;
}

.email-company {
  color: #898989;
  font-size: 16px;
}

.company-wrapper {
  width: 100%;
}

.address-company {
  color: #898989;
  font-size: 16px;
  text-align: right;
  width: 415px;
}

.item-detail {
  margin-bottom: 7px;
}

.header-detail {
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  padding: 20px 30px;
}

.table-payroll-slip td.td-block.header-table-blue {
  background-color: #68caf8;
  color: #000;
  padding: 15px 12px;
}

.table-payroll-slip td.td-block.header-table-red {
  background-color: #ff8d8d;
  color: #000;
  padding: 15px 12px;
}
.title-payroll {
  font-size: 32px;
  color: #263238;
  font-weight: 600;
}
</style>
